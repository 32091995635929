import _ from 'underscore'
import XLSX from 'xlsx-js-style'

export default data => {
  return new Promise(function (resolve, reject) {
    try {
      const averegeCPP = data['averegeCPP'] || []
      const averegeCCS = data['averegeCSS'] || []
      const averegeProduction = data['averegeProduction'] || []
      const averegeChronicMastitis = data['averegeChronicMastitis'] || []
      const averegeClinicalMastitis = data['averegeClinicalMastitis'] || []
      const averegeHealing = data['averegeHealing'] || []
      const averegeInfections = data['averegeInfections'] || []
      const visitTable = data['visitsMade'] || []

      const months = [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ]

      const rowMonths = [
        {
          v: 'Ano',
          t: 's',
          s: {
            font: { bold: true, color: { rgb: 'ffffff' } },
            fill: { fgColor: { rgb: '0d47a1' } },
          },
        },
        {
          v: 'Jan',
          t: 's',
          s: {
            font: { bold: true, color: { rgb: 'ffffff' } },
            fill: { fgColor: { rgb: '1a237e' } },
          },
        },
        {
          v: 'Fev',
          t: 's',
          s: {
            font: { bold: true, color: { rgb: 'ffffff' } },
            fill: { fgColor: { rgb: '1a237e' } },
          },
        },
        {
          v: 'Mar',
          t: 's',
          s: {
            font: { bold: true, color: { rgb: 'ffffff' } },
            fill: { fgColor: { rgb: '1a237e' } },
          },
        },
        {
          v: 'Abr',
          t: 's',
          s: {
            font: { bold: true, color: { rgb: 'ffffff' } },
            fill: { fgColor: { rgb: '1a237e' } },
          },
        },
        {
          v: 'Mai',
          t: 's',
          s: {
            font: { bold: true, color: { rgb: 'ffffff' } },
            fill: { fgColor: { rgb: '1a237e' } },
          },
        },
        {
          v: 'Jun',
          t: 's',
          s: {
            font: { bold: true, color: { rgb: 'ffffff' } },
            fill: { fgColor: { rgb: '1a237e' } },
          },
        },
        {
          v: 'Jul',
          t: 's',
          s: {
            font: { bold: true, color: { rgb: 'ffffff' } },
            fill: { fgColor: { rgb: '1a237e' } },
          },
        },
        {
          v: 'Ago',
          t: 's',
          s: {
            font: { bold: true, color: { rgb: 'ffffff' } },
            fill: { fgColor: { rgb: '1a237e' } },
          },
        },
        {
          v: 'Set',
          t: 's',
          s: {
            font: { bold: true, color: { rgb: 'ffffff' } },
            fill: { fgColor: { rgb: '1a237e' } },
          },
        },
        {
          v: 'Out',
          t: 's',
          s: {
            font: { bold: true, color: { rgb: 'ffffff' } },
            fill: { fgColor: { rgb: '1a237e' } },
          },
        },
        {
          v: 'Nov',
          t: 's',
          s: {
            font: { bold: true, color: { rgb: 'ffffff' } },
            fill: { fgColor: { rgb: '1a237e' } },
          },
        },
        {
          v: 'Dez',
          t: 's',
          s: {
            font: { bold: true, color: { rgb: 'ffffff' } },
            fill: { fgColor: { rgb: '1a237e' } },
          },
        },
        {
          v: 'Total',
          t: 's',
          s: {
            font: { bold: true, color: { rgb: 'ffffff' } },
            fill: { fgColor: { rgb: '0d47a1' } },
          },
        },
      ]

      const rowEmpty = [
        {
          v: '',
          t: 's',
        },
      ]

      const rowTitle1 = [
        {
          v: 'CONTROLE DE VISITAS',
          t: 's',
          s: {
            font: {
              sz: 14,
              bold: true,
            },
          },
        },
      ]

      const rowTitle2 = [
        {
          v: 'PRODUÇÃO DIÁRIA - ACOMPANHAMENTO MENSAL',
          t: 's',
          s: {
            font: {
              sz: 14,
              bold: true,
            },
          },
        },
      ]

      const rowTitle22 = [
        {
          v: 'VOLUME DE LEITE (L/DIA) - ACOMPANHAMENTO MENSAL',
          t: 's',
          s: {
            font: {
              sz: 14,
              bold: true,
            },
          },
        },
      ]

      let rowTitle3 = [
        {
          v: 'CPP (CBT) - ACOMPANHAMENTO MENSAL',
          t: 's',
          s: {
            font: {
              sz: 14,
              bold: true,
            },
          },
        },
      ]

      let rowTitle4 = [
        {
          v: 'CCS - ACOMPANHAMENTO MENSAL',
          t: 's',
          s: {
            font: {
              sz: 14,
              bold: true,
            },
          },
        },
      ]

      let rowTitle5 = [
        {
          v: 'PREVALÊNCIA DE MASTITE CLÍNICA',
          t: 's',
          s: {
            font: {
              sz: 14,
              bold: true,
            },
          },
        },
      ]

      let rowTitle6 = [
        {
          v: 'TAXA DE MASTITE CRÔNICA (%)',
          t: 's',
          s: {
            font: {
              sz: 14,
              bold: true,
            },
          },
        },
      ]

      let rowTitle7 = [
        {
          v: 'TAXA DE CURA (%)',
          t: 's',
          s: {
            font: {
              sz: 14,
              bold: true,
            },
          },
        },
      ]

      let rowTitle8 = [
        {
          v: 'TAXA DE NOVAS INFECÇÕES (%)',
          t: 's',
          s: {
            font: {
              sz: 14,
              bold: true,
            },
          },
        },
      ]

      const wb = XLSX.utils.book_new()

      // Step Controle de Visitas
      const ws = XLSX.utils.aoa_to_sheet([rowTitle1, rowMonths])
      let result = _.groupBy(visitTable, item => item.year)
      let DataAno = []

      Object.keys(result).forEach(ano => {
        const avgAllMeses = []

        for (let i = 0; i < 12; i++) {
          avgAllMeses[months[i]] = 0
        }

        result[ano].forEach(item => {
          avgAllMeses[0] = ano
          avgAllMeses[months[item.month - 1]] = item.avg
        })

        DataAno.push(avgAllMeses)
      })

      Object.keys(DataAno).forEach(ano => {
        const allMeses = DataAno[ano]
        const meses = []
        Object.keys(allMeses).forEach(mes => {
          meses.push(allMeses[mes])
        })
        let sum = 0

        for (let i = 1; i < 13; i++) {
          sum += meses[i]
        }
        meses.push(sum)
        XLSX.utils.sheet_add_aoa(ws, [meses], { origin: -1 })
      })

      XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })
      XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })

      // Step PRODUÇÃO ----------------------------
      if (
        averegeChronicMastitis ||
        averegeClinicalMastitis ||
        averegeHealing ||
        averegeInfections
      ) {
        XLSX.utils.sheet_add_aoa(ws, [rowTitle22], { origin: -1 })
      } else {
        XLSX.utils.sheet_add_aoa(ws, [rowTitle2], { origin: -1 })
      }
      XLSX.utils.sheet_add_aoa(ws, [rowMonths], { origin: -1 })

      result = _.groupBy(averegeProduction, item => item.year)
      DataAno = []

      Object.keys(result).forEach(ano => {
        const avgAllMeses = []

        for (let i = 0; i < 12; i++) {
          avgAllMeses[months[i]] = 0
        }

        result[ano].forEach(item => {
          avgAllMeses[0] = ano
          avgAllMeses[months[item.month - 1]] = item.avg
        })

        DataAno.push(avgAllMeses)
      })

      Object.keys(DataAno).forEach(ano => {
        const allMeses = DataAno[ano]
        const meses = []
        Object.keys(allMeses).forEach(mes => {
          meses.push(allMeses[mes])
        })
        let sum = 0

        for (let i = 1; i < 13; i++) {
          sum += meses[i]
        }
        meses.push(sum)
        XLSX.utils.sheet_add_aoa(ws, [meses], { origin: -1 })
      })

      XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })
      XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })

      // Step CPP ----------------------------

      XLSX.utils.sheet_add_aoa(ws, [rowTitle3], { origin: -1 })
      XLSX.utils.sheet_add_aoa(ws, [rowMonths], { origin: -1 })

      result = _.groupBy(averegeCPP, item => item.year)
      DataAno = []

      Object.keys(result).forEach(ano => {
        const avgAllMeses = []

        for (let i = 0; i < 12; i++) {
          avgAllMeses[months[i]] = 0
        }

        result[ano].forEach(item => {
          avgAllMeses[0] = ano
          avgAllMeses[months[item.month - 1]] = item.avg
        })

        DataAno.push(avgAllMeses)
      })

      Object.keys(DataAno).forEach(ano => {
        const allMeses = DataAno[ano]
        const meses = []
        Object.keys(allMeses).forEach(mes => {
          meses.push(allMeses[mes])
        })
        let sum = 0

        for (let i = 1; i < 13; i++) {
          sum += meses[i]
        }
        meses.push(sum)
        XLSX.utils.sheet_add_aoa(ws, [meses], { origin: -1 })
      })

      XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })
      XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })

      // Step CCS ----------------------------

      XLSX.utils.sheet_add_aoa(ws, [rowTitle4], { origin: -1 })
      XLSX.utils.sheet_add_aoa(ws, [rowMonths], { origin: -1 })

      result = _.groupBy(averegeCCS, item => item.year)
      DataAno = []

      Object.keys(result).forEach(ano => {
        const avgAllMeses = []

        for (let i = 0; i < 12; i++) {
          avgAllMeses[months[i]] = 0
        }

        result[ano].forEach(item => {
          avgAllMeses[0] = ano
          avgAllMeses[months[item.month - 1]] = item.avg
        })

        DataAno.push(avgAllMeses)
      })

      Object.keys(DataAno).forEach(ano => {
        const allMeses = DataAno[ano]
        const meses = []
        Object.keys(allMeses).forEach(mes => {
          meses.push(allMeses[mes])
        })
        let sum = 0

        for (let i = 1; i < 13; i++) {
          sum += meses[i]
        }
        meses.push(sum)
        XLSX.utils.sheet_add_aoa(ws, [meses], { origin: -1 })
      })

      XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })
      XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })

      // Step Mastite Clinica ----------------------------
      if (averegeClinicalMastitis) {
        XLSX.utils.sheet_add_aoa(ws, [rowTitle5], { origin: -1 })
        XLSX.utils.sheet_add_aoa(ws, [rowMonths], { origin: -1 })

        result = _.groupBy(averegeClinicalMastitis, item => item.year)
        DataAno = []

        Object.keys(result).forEach(ano => {
          const avgAllMeses = []

          for (let i = 0; i < 12; i++) {
            avgAllMeses[months[i]] = 0
          }

          result[ano].forEach(item => {
            avgAllMeses[0] = ano
            avgAllMeses[months[item.month - 1]] = item.avg
          })

          DataAno.push(avgAllMeses)
        })

        Object.keys(DataAno).forEach(ano => {
          const allMeses = DataAno[ano]
          const meses = []
          Object.keys(allMeses).forEach(mes => {
            meses.push(allMeses[mes])
          })
          let sum = 0

          for (let i = 1; i < 13; i++) {
            sum += meses[i]
          }
          meses.push(sum)
          XLSX.utils.sheet_add_aoa(ws, [meses], { origin: -1 })
        })

        XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })
        XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })
      }

      // Step Mastite Cronica ----------------------------
      if (averegeChronicMastitis) {
        XLSX.utils.sheet_add_aoa(ws, [rowTitle6], { origin: -1 })
        XLSX.utils.sheet_add_aoa(ws, [rowMonths], { origin: -1 })

        result = _.groupBy(averegeChronicMastitis, item => item.year)
        DataAno = []

        Object.keys(result).forEach(ano => {
          const avgAllMeses = []

          for (let i = 0; i < 12; i++) {
            avgAllMeses[months[i]] = 0
          }

          result[ano].forEach(item => {
            avgAllMeses[0] = ano
            avgAllMeses[months[item.month - 1]] = item.avg
          })

          DataAno.push(avgAllMeses)
        })

        Object.keys(DataAno).forEach(ano => {
          const allMeses = DataAno[ano]
          const meses = []
          Object.keys(allMeses).forEach(mes => {
            meses.push(allMeses[mes])
          })
          let sum = 0

          for (let i = 1; i < 13; i++) {
            sum += meses[i]
          }
          meses.push(sum)
          XLSX.utils.sheet_add_aoa(ws, [meses], { origin: -1 })
        })

        XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })
        XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })
      }

      // Step Taxa de cura ----------------------------
      if (averegeHealing) {
        XLSX.utils.sheet_add_aoa(ws, [rowTitle7], { origin: -1 })
        XLSX.utils.sheet_add_aoa(ws, [rowMonths], { origin: -1 })

        result = _.groupBy(averegeHealing, item => item.year)
        DataAno = []

        Object.keys(result).forEach(ano => {
          const avgAllMeses = []

          for (let i = 0; i < 12; i++) {
            avgAllMeses[months[i]] = 0
          }

          result[ano].forEach(item => {
            avgAllMeses[0] = ano
            avgAllMeses[months[item.month - 1]] = item.avg
          })

          DataAno.push(avgAllMeses)
        })

        Object.keys(DataAno).forEach(ano => {
          const allMeses = DataAno[ano]
          const meses = []
          Object.keys(allMeses).forEach(mes => {
            meses.push(allMeses[mes])
          })
          let sum = 0

          for (let i = 1; i < 13; i++) {
            sum += meses[i]
          }
          meses.push(sum)
          XLSX.utils.sheet_add_aoa(ws, [meses], { origin: -1 })
        })

        XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })
        XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })
      }

      // Step Taxa de infeccao ----------------------------
      if (averegeInfections) {
        XLSX.utils.sheet_add_aoa(ws, [rowTitle8], { origin: -1 })
        XLSX.utils.sheet_add_aoa(ws, [rowMonths], { origin: -1 })

        result = _.groupBy(averegeInfections, item => item.year)
        DataAno = []

        Object.keys(result).forEach(ano => {
          const avgAllMeses = []

          for (let i = 0; i < 12; i++) {
            avgAllMeses[months[i]] = 0
          }

          result[ano].forEach(item => {
            avgAllMeses[0] = ano
            avgAllMeses[months[item.month - 1]] = item.avg
          })

          DataAno.push(avgAllMeses)
        })

        Object.keys(DataAno).forEach(ano => {
          const allMeses = DataAno[ano]
          const meses = []
          Object.keys(allMeses).forEach(mes => {
            meses.push(allMeses[mes])
          })
          let sum = 0

          for (let i = 1; i < 13; i++) {
            sum += meses[i]
          }
          meses.push(sum)
          XLSX.utils.sheet_add_aoa(ws, [meses], { origin: -1 })
        })

        XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })
        XLSX.utils.sheet_add_aoa(ws, [rowEmpty], { origin: -1 })
      }

      XLSX.utils.book_append_sheet(wb, ws, 'Dados')

      // STEP 4: Write Excel file
      XLSX.writeFile(wb, 'dados-dashboard.xlsx')
    } catch (err) {
      reject(err)
    }
  })
}
